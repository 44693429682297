@import "styles/mixins.scss";
@import "styles/colors.scss";

.header {
	background: $dark_blue;
	color: #fff;

	a {
		color: #fff;
		text-decoration: none;
	}
}

.header-top,
.header-toggle-hc,
.header-logos,
.font-size-switcher,
.header-accessibility-box > div {
	@include flex( center );
}

.header-top {
	padding-left: 15px;
	max-width: 1920px;
	min-height: 58px;
	margin: 0 auto;

	.language-switcher {
		margin-left: 15px;
	}
}

.header-logo {
	flex: 0 0 118px;
	margin-right: 17px;
}

.header-toggle-hc {
	font-size: 1em;

	svg {
		min-width: 24px;
		max-width: 24px;
		margin-right: 10px;
	}
}

.header-logos {
	margin-left: auto;
	margin-right: 30px;

	a {
		margin-left: 16px;
	}
}

.header-logos a,
.header-accessibility-box a {
	min-width: 40px;
	max-width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 1px solid $turquoise;

	@include flex-centered;

	transition: background .5s;

	&:hover {
		background: rgba( 255, 255, 255, .15);
	}

	> svg {
		max-width: 70%;
	}
}


.header-actions {
	background: $black_pearl;
	position: relative;
	padding: 10px 35px 12px 15px;
	justify-content: flex-end;

	.square-button {
		width: 42px;
		min-width: 42px;
		height: 42px;
		margin-left: 10px;
		margin-right: 20px;

		display: none;
	}


	&:before {
		display: block;
		content: "";
		background-color: $black_pearl;
		transform: skew( 15deg, 0 );
		position: absolute;
		height: calc(100% + 10px);
		width: 20px;
		bottom: 0;
		left: -12px;
	}
}


.header-accessibility-btn,
.header-accessibility-box {
	border: 1px solid $turquoise;
	background: $black_pearl;
	border-radius: 8px;
}

.header-accessibility {
	position: relative;
}

.header-accessibility-btn {
	cursor: pointer;

	display: flex;
	align-items: center;
	font-size: 1em;

	padding: 6px 11px;	
	display: flex;
	align-items: center;

	transition: background .5s;

	&:hover {
		background: rgba( 255, 255, 255, .15);
	}

	svg {
		margin-right: 8px;
	}
}

.header-accessibility-box {
	width: 234px;

	position: absolute;
	z-index: 1000;
	top: 40px;
	left: 50%;
	margin-left: -117px;
	padding: 13px 18px;

	> div:not(.font-size-switcher) {
		margin-bottom: 10px;
		
		a {
			margin-right: 10px;
		}
	}

	.font-size-switcher {

		justify-content: center;

		a.font-size-normal {
			border: 0;
			background: transparent;
			svg {
				width: auto;
				max-height: 20px;
			}
		}
	}
}

.header-app-buttons {
	display: flex;

	a.reg {
		padding-left: 10px;
		padding-right: 35px;
	}

	a.login {
		padding-left: 10px;
		padding-right: 25px;
		background: #2C627F;

		&::before {
			background: transparent;
			border-right-color:#2C627F;
		}
	}
	
	a {
		position: relative;
		display: flex;
		align-items: center;
		min-height: 58px;
		background: #194862;

		padding-top: 15px;
		padding-bottom: 15px;

		transition: background .5s;

		span {
			margin-right: 16px;
		}

		&::before {
			content: "";
			width: 0;
			height: 0;
			border-top: 0 solid transparent;
			border-bottom: 58px solid transparent;
			border-right: 20px solid #194862;
			position: absolute;
			left: -20px;
			top: 0;
			z-index: 2;
			transition: all .5s;
		}

		&:hover {
			background: #476D81;
			&::before {
				border-right-color: #476D81;
			}
		}
	}	
}

.header-bottom {
	padding: 24px 15px 22px;
	min-height: 82px;

	display: flex;
	align-items: center;
	justify-content: center;

	a {
		font-weight: 400;
		font-size: 1.714em;
		text-transform: uppercase;
		text-align: center;
	}
}
