@mixin pie( $size ) {
	.pie, 
	.pie__hold, 
	.pie__slice {
		width: $size;
		height: $size;
	}

	.pie__slice {
		clip: rect( 0px, $size / 2, $size, 0px );
	}
	
	.pie__hold {
		clip: rect( 0px, $size, $size, $size / 2);
	}

	.pie__hold.rest {
		clip: rect( 0px, $size, $size, 0px );

		.pie__slice {
			clip: rect( 0px, $size, $size, 0px );
		}
	}
}

.pie-chart {
	display: flex;
	flex-wrap: wrap;
	padding: 40px 0;

	h3 {
		width: 100%;
		margin-top: 0;
		margin-bottom: 30px;
		font-weight: 600;
		font-size: 1.28em;
	}
}

.pie-chart__labels {	

	margin-bottom: 25px;

	.flex {
		display: flex;
		font-size: 1.42em;
		margin-bottom: 15px;

		> div:first-child {
			width: 17px;
			min-width: 17px;
			height: 17px;
			border-radius: 50%;
			margin-right: 16px;
		}

		> div {
			strong, span {
				display: block;
			}
		}

		@media (max-width: 1400px ){
			font-size: 1.14em;

			> div:first-child { margin-right: 8px; }
		}

		@media (max-width: 1199px ) {
			font-size: 1em;
		}
	}
}

@include pie( 300px );

@media (max-width: 360px) {
	@include pie( 290px );
}

.pie {
	overflow: hidden;
	position: relative;
	margin: 0 auto;
}

.pie__slice,
.pie__hold {
	transition: all 1s;
}

.pie__slice {
	position: absolute;
	border-radius: 100%;
}

.pie__hold {
	position: absolute;
	border-radius: 100%;
	transform: rotate( 0deg );
	z-index: 1;

	&:first-child .pie__slice { background: #009E73; }
	&:nth-child(2) .pie__slice { background: #297BBA; }
	&:nth-child(3) .pie__slice { background: #FF6363; }
}

.pie__hold.rest {
	z-index: 0;
}
  

@media (max-width: 991px) {
	.pie-chart h3 {
		font-size: 1.07em;
	}
}