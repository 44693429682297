@import "styles/colors.scss";
@import "styles/mixins.scss";

.tile-with-graphics {
	@include img-bg;

	padding: 32px 42px;
	height: 346px;
	margin-bottom: 50px;
	border-radius: 25px;
	background-color: rgba( 0, 0, 0, .1 );
		
	@media (max-width: 944px) {
		height: calc((100vw - 30px ) * 0.378142077 );
	}

	@media (max-width: 767px) {
		padding: 20px 15px;
		margin-bottom: 30px;
	}

	@media (max-width: 500px) {
		padding: 15px 10px;
	}

	h2 {
		margin: 0;
		font-weight: 900;
		font-size: 2em;
		max-width: 325px;

		@media (max-width: 1400px) {
			font-size: 1.71em;
		}

		@media (max-width: 600px) {
			font-size: 1.35em;
		}
	}
}


body.contrast {
	.tile-with-graphics {
		filter: grayscale(1);
		color: #000;
		background-color: rgba( 255, 255, 255, .3 );
	}
}