@import "styles/colors.scss";

body.contrast {

	.header-actions {
		background: #000;

		&::before {
			background: #000;
		}
	}

	.header-bottom a,
	.header-search input,
	.header-bottom h1 {
		color: $yellow;
	}
	
	.header-search,
	.header-logos a, 
	.header-accessibility-box a {
		border-color: $yellow;
	}
	
	.header-accessibility-btn,
	.header-accessibility-box {
		border-color: $yellow;
		background: #000;
		color: $yellow;
	}

	.header-top, 
	.header-logo,
	.header-bottom {
		border-color: $yellow;	
	}

	.header {
		background-color: $black;
	}

	.header-app-buttons a {

		&:first-child {
			background: $black;
			&:before {
				border-right-color: $black;
			}
		}

		&:last-child {
			background: #000;

			&::before {
				// background: $black;
				border-right-color: #000;
			}
		}
	}

	@media (max-width: 1150px) {
		.header-top {
			background: #000;
		}		
	}
}