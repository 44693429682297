@import "styles/mixins.scss";

.bar-chart {
	margin-top: 30px;
	margin-bottom: 40px;

	h3 {
		font-size: 1.71em;
		font-weight: 700;
		margin-bottom: 16px;
	}

	h4 {
		font-size: 1.28em;
		font-weight: 600;
	}
}

.bar-chart-wrap {
	display: flex;
	overflow: hidden;
	margin: 0 -167px;

	@media (max-width: 1330px ) {
		margin: 0;
	}
}

.bar-chart-legend {
   @include chart-legend;
}

.bar-chart-main {
	display: flex;
	flex-wrap: wrap;
	overflow-y: auto;
	padding-bottom: 5px;
   width: 100%;
}


.bar-chart-scale,
.bar-chart-bars {
	height: 410px;
	padding-top: 60px;
}

.bar-chart-bars {
	display: flex;
	flex: auto;
} 

.bar-chart-scale {
	font-size: 16px;
	padding-right: 10px;

	display: flex;
	flex-direction: column;
	flex: 0 0 85px;

	div {
		position: relative;
		flex: 1 1;

		&::after,
		&::before {
			content: '';

			position: absolute;
			z-index: 0;
			top: 0;
			left: calc( 100% + 10px );

			height: 1px;
			width: 9999px;
			background: #41618027;
		}

		&::before {
			display: block;
		}

		span {
			position: relative;
			top: -8px;
		}
	}

	div:last-child::after {
		display: block;
		bottom: 0;
		top: auto;
	}
}


.bar-chart-labels {
	display: flex;
	flex: 0 0 100%;

	> div {

		display: flex;
		flex-wrap: wrap;
		box-sizing: content-box;
      margin: 0 10px;
      flex: 1 1 120px;
      padding: 10px 0;
      
		> div {
			font-weight: 500;
         width: 100%;
			text-align: center;
		}
	}
}

@media (max-width: 991px) {

	.bar-chart {
		h3 {
			font-size: 1.28em;
		}

		h4 {
			font-size: 1.07em;
		}
	}

	.bar span {
		font-size: 14px;
	}

	.bar-chart-labels > div > div {
		font-size: 11px;
	}
}

@media (max-width: 360px) {
	.bar-chart-scale {
		font-size: 11px;
		flex: 0 0 63px;
	}
}

body.contrast {
	.bar-chart-scale div::after, 
	.bar-chart-scale div::before {
		background: rgba( 255, 255, 255, .15 );
	}
}