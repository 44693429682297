@import "styles/colors.scss";

@media (max-width: 1450px) {
	.header-toggle-hc {
		font-size: 1em;
	}
}

@media (max-width: 1200px) {

	.header-app-buttons {
		display: none;
	}

	.header-logo {
		flex: 0 0 90px
	}

	.header-top {
		padding-right: 15px;
	}

	.header-accessibility-btn {
		background: $dark_blue;
	}

	.header-accessibility-box {
		left: auto;
		right: 0;
	}

	.header-actions {

		background: $dark_blue;
		padding: 8px 0;

		.square-button {
			display: flex;
		}

		&::before {
			display: none;
		}
	}
}

@media (max-width: 767px) {
	.header-logo {
		display: none;
	}
}

@media (max-width: 613px) {
	.header .header-logos {
		display: none;
	}

	.header-actions {
		margin-left: auto;
	}
}

@media (max-width: 500px) { 
	
	.header-accessibility-btn {
		min-width: 50px;
		justify-content: center;

		svg {
			margin: 0;
		}

		span {
			display: none;
		}
	}

	
}
