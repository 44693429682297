@import "styles/colors.scss";
@import "styles/mixins.scss";

.tiles-section {
	padding: 40px 0;

	.container {
		> h3 {
			font-weight: 700;
			margin-bottom: 24px;
			font-size: 3em;
			color: $green;

			
			@media ( max-width: 680px ) {
				font-size: 1.5em;
			}
		}
	
		> p {
			font-weight: 600;
			color: $green;
			margin-bottom: 40px;
		}
	}
}

.tiles-section-items {
	display: grid;
	grid-template-columns: repeat( 2, 1fr );
	gap: 30px;

	@media ( max-width: 1023px ) {
		grid-template-columns: 1fr;
	}

	@media ( max-width: 680px ) {
		gap: 16px;
	}
}

body.contrast {
	.tiles-section {
		.container {
			> h3, > p {
				color: $yellow;
			}
		}
	}
}