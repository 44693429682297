@import "styles/colors.scss";
@import "styles/mixins.scss";

.tile {
	position: relative;
	overflow: hidden;

	padding: 48px 48px 90px;
	color: $green;
	text-decoration: none;

	border: 1px solid rgba( $green, .4 );
	border-radius: 30px;

	transition: background .7s, color .7s;

	&:hover {
		color: $white;
		background: $green;
		border-radius: $green;
		> svg { fill: $white; }
	}

	> svg {
		fill: $green;
		transition: fill .7s;
		position: absolute;
		right: 48px;
		bottom: 48px;
	}

	.tile-title, > p {
		position: relative;
		z-index: 5;
	}
}

.tile.with-img {
	min-height: 392px;
	transition: background .35s, color .35s;

	&:hover {
		background: transparent;
	
		.tile-img {
			width: 100%;
			height: 100%;
			transform: translate( 0, 0);
			border-radius: 30px;
		}
	}
}

.tile-title {
	font-size: 1.5em;
	font-weight: 700;
	margin-bottom: 16px;
}

.tile-img {

	@include img-bg;

	width: 258px;
	height: 258px;
	border-radius: 50%;
	border-bottom-left-radius: 30px !important;
	overflow: hidden;

	position: absolute;
	left: 0;
	bottom: 0;
	transform: translate( -44px, 44px);

	transition: border-radius .35s, width .35s linear, height .35s linear, transform .3s linear;
}

@media ( max-width: 680px ) {
	.tile {

		min-height: 239px;
		padding: 24px 16px 40px;

		> svg {
			width: 26px;
			right: 16px;
			bottom: 24px;
		}
	}

	.tile-title {
		font-size: 1.25em;
	}

	.tile.with-img {
		min-height: 330px;
	}
}

body.contrast {
	.tile {
		color: $yellow;
		border-color: $yellow;

		> svg { fill: $yellow; }

		&:hover {
			background: $yellow;
			color: $black;

			> svg { fill: $black; }
		}
	}

	.tile.with-img {

		.tile-img {
			filter: grayscale(1);
		}

		&:hover {
			color: $yellow;
			background: transparent;

			> svg { fill: $yellow; }
		}
	}
}