@import "styles/colors.scss";
@import "styles/mixins.scss";

.pagination {
	@include flex( center, center );
	padding: 30px 0;
	gap: 8px;

	span {
		width: 24px;
		text-align: center;
	}

	button {
		cursor: pointer;
		text-align: center;

		width: 24px;
		height: 24px;
		border-radius: 8px;

		background: transparent;
		padding: 0;
		border: 0;
		color: $green;

		font-weight: 600;
		font-size: 1.125em;
	}

	button.next,
	button.prev {		
		@include circle( 38px );
		display: flex;
		border: 1px solid $gray;
		background: transparent;

		svg {
			margin: auto;
			fill: $green;
		}
	}

	button.active {
		background: $green;
		color: $white;
	}
}

body.contrast {
	.pagination {
		button {
			color: $yellow;
		}

		button.active {
			background: $yellow;
			color: $black;
		}

		button.next,
		button.prev  {
			border-color: $yellow;

			svg { fill: $yellow; }
		}
	}
}