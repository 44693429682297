@import "styles/colors.scss";
@import "styles/mixins.scss";

.carbon-footprint-page {
	section {
		padding: 50px 0;

		@media (max-width: 1199px) {
			padding: 30px 0;
		}
	}
}