@import "styles/colors.scss";
@import "styles/mixins.scss";

.navigation {

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	a, span {
		color: $white;
	}

	svg {
		fill: $white;
	}

	li {
		padding: 16px;
	}

	nav ul {
		display: flex;
		justify-content: flex-end;
		gap: 20px;

		a, span {
			cursor: pointer;
			font-weight: 600;
			text-decoration: none;

			svg {
				transition: transform .2s;
				margin-left: 8px;
			}
		}

		ul {
			display: none;
		}

		a.active {
			padding-bottom: 6px;
			border-bottom: 4px solid $white;
		}
	}

	nav .posts-active {
		> svg {
			transform: rotate( 180deg );
		}
	}
}

.navigation-posts {
	margin-top: 10px;
	padding: 30px;

	border-radius: 24px;
	background: rgba( 0, 0, 0, .8 );
	overflow: hidden;
}

.navigation-posts-items {

	display: flex;
	gap: 20px;
	padding-bottom: 10px;
	overflow-x: auto;

	a {
		flex: 0 0 193px;
		font-weight: 600;
		color: $white;
		text-decoration: none;

		> div {
			@include img-bg;
			background-color: rgba( 255, 255, 255,.5 );
			width: 193px;
			height: 156px;
			border-radius: 20px;
			margin-bottom: 16px;
		}
	}
}

.navigation-mobile-btn {
	display: none;
	padding: 7px;
	border: 0;
	background: transparent;

	position: absolute;
	top: 24px;
	right: 24px;

	svg {
		margin: auto;
		fill: $white;
	}
}


@media ( max-width: 1023px) {

	.navigation-posts {
		display: none !important;
	}

	.navigation {
		z-index: 999 !important;

		.navigation-mobile-btn {
			display: flex;
			top: 0;
			right: 0;
		}
		
		nav {
			display: none;

			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height: calc( var(--vh, 1vh ) * 100 );
			overflow: auto;
			padding: 24px;

			background: $green;
			text-align: left;

			ul {
				display: block;
			}

			.posts-active {
				ul {
					margin-top: 16px;
					display: block;
				}
			}

			.navigation-mobile-btn {
				display: none;
			}
		}
	}

	.navigation.open {

		.navigation-mobile-btn { display: none; }

		nav {
			display: block;

			.navigation-mobile-btn {
				display: flex;
				top: 24px;
				right: 24px;
			}
		}
	}
}

@media ( max-width: 450px ) {
	.navigation.open {
		nav {
			padding: 20px 15px;

			.navigation-mobile-btn {
				top: 20px;
				right: 15px;
			}
		}
	}
}

body.contrast {
	.navigation {
		svg { fill: $yellow; }
		
		a, span {
			color: $yellow;
		}

		a.active {
			border-color: $yellow;
		}
	}

	.navigation-posts a > div {
		filter: grayscale(1);
	}

	@media ( max-width: 1023px) {
		.navigation.open nav {
			background: $black;
		}
	}
}