@import "styles/colors.scss";
@import "styles/mixins.scss";

.main-tiles-section {
	padding: 40px 0;
}

.main-tiles-section-items {
	display: grid;
	grid-template-columns: repeat( 6, 1fr );
	gap: 30px;

	.tile {
		&:first-child {
			grid-column: span 4;
		}

		&:nth-child(2) {
			grid-column: span 2;
		}

		&:nth-child(3),
		&:nth-child(4) {
			grid-column: span 3;
		}
	}

	
	@media ( max-width: 1023px ) {
		grid-template-columns: 1fr;

		.tile {
			grid-column: 1 !important;
		}
		
	}

	@media ( max-width: 680px ) {
		gap: 16px;
	}
}
