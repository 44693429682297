@import "styles/colors.scss";
@import "styles/mixins.scss";

.arrows {
	@include flex( center );
	gap: 8px;

	button {
		@include circle( 48px );
		cursor: pointer;
		display: flex;
		background: transparent;
		border: 1px solid $gray;

		transition: all .4s;

		svg {
			fill: $green;
			margin: auto;
			max-width: 50%;
			transition: all .4s;
		}

		&:hover {
			background: $green;
			border-color: $green;

			svg { fill: $white; }
		}
	}
}