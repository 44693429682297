$white: #ffffff;
$black: #23242a;
$dark: #040B13;
$yellow: #ffd401;
$dark_blue: #0B2640;
$turquoise: #5FB4D9;

$black_pearl: #001528;
$quartz: #DFE0EB;

$green: #216D4F;
$light_black: #505050;
$green_link: #58AB8A;
$gray: #C7D9CD;