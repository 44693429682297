.bar-group {
   display: flex;
   justify-content: center;
   gap: 3px;
   height: 100%;
	flex: 1 1 120px;

   padding: 0 20px;
   margin: 0 10px;
}

.bar {
   display: flex;
   flex-direction: column-reverse;

	position: relative;
   height: 100%;
   max-width: 40px;

	span {
		font-weight: 500;
		font-size: 19px;
		display: block;
	}
}

.bar-value-view {
   border-radius: 3px 3px 0 0;
	min-height: 3px;
	width: 100%;
	background: #297BBA;
	transition: height 1s;
}