@import "styles/colors.scss";


@mixin flex-column( $align: unset, $justify: unset ) {
	@include flex( $align, $justify, column );
}

@mixin flex( $align: unset, $justify: unset, $direction: row ) {
	display: flex;
	flex-direction: $direction;
	align-items: $align;
	justify-content: $justify;	
}

@mixin row {
	display: flex;
	flex-wrap: wrap;
}

@mixin flex-centered {
	@include flex( center, center );
}

@mixin circle( $size ) {
	border-radius: 50%;
	width: $size;
	min-width: $size;
	height: $size;
}

@mixin img-bg {
    background-repeat: no-repeat;
	background-position: 50%;
    background-size: cover;
}

@mixin main-section {
	margin: 0 auto;
	padding: 16px 30px;

	> div {
		position: relative;
		overflow: hidden;
		border-radius: 48px;
		height: 100%;
	}

	.navigation {
		position: absolute;
		z-index: 10;
		right: 80px;
		left: 80px;
		top: 30px;
	}

	h1 {
		font-size: 4.625em;
		margin-bottom: 24px;

		@media ( max-width: 1550px ) {
			font-size: 3em;
		}

		@media ( max-width: 450px ) {
			font-size: 2.5em;
		}
	}
	
	p {
		font-weight: 700;
	}

	@media ( max-width: 1400px ) {
		padding: 0;

		> div {
			border-radius: 0;
		}

		.navigation {
			left: 40px;
			right: 40px;
		}
	}

	@media ( max-width: 680px ) {
		.navigation {
			left: 20px;
			right: 20px;
		}
	}

	@media ( max-width: 450px ) {
		.navigation {
			left: 15px;
			right: 15px;
		}
	}
}

@mixin chart-legend {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   gap: 10px 20px;

   > div {
      display: flex;
      align-items: center;
      gap: 8px;

      > div {
         border-radius: 4px;
         width: 24px;
         height: 24px;
      }
   }
}