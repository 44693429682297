@import "styles/colors.scss";

body.contrast {
	.loader, 
	.loader.white {
		.cssload-whirlpool,
		.cssload-whirlpool::before,
		.cssload-whirlpool::after{
			border: 1px solid rgba(255, 212, 1, 1);
			border-left-color: rgba(255, 212, 1, .15);
		}
	}
} 