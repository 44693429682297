@import "styles/mixins.scss";
@import "styles/colors.scss";

.btn {

	cursor: pointer;
	text-decoration: none;
	
	display: inline-block;
	font-weight: 600;
	font-size: 1em;

	border: 2px solid $green;
	border-radius: 99px;

	background: $green;
	color: $white;

	padding: 18px 30px;
	
	transition: color .4s, background .4s;

	&:hover {
		background: $white;
		color: $green;
	}

	@media ( max-width: 680px ) {
		font-size: 0.875em;
	}
}

.btn[disabled] {
	cursor: auto;
	opacity: .6;

	&:hover {
		background: $green;
		color: $white;
	}
}

body.contrast {
	.btn {
		background: $yellow;
		border-color: $yellow;
		color: $black;

		&:hover {
			border-color: $yellow;
			background: rgba( $yellow, .7 );
			color: $black;
		}
	}
}