@import "./colors.scss";
@import "./mixins.scss";

* {
	box-sizing: border-box;
}

body {
	font-size: 16px;
	font-family: 'Montserrat', sans-serif;
	margin: 0;
	color: $light_black;
}

#gdynia-klimat {
	display: flex;
	flex-direction: column;
	height: calc( var(--vh, 1vh ) * 100 );
	
	main {
		flex: auto;
	}
}

img, svg {
	max-width: 100%;
	height: auto;
}

a {
	color: $green;
	text-decoration: underline;
}

ul, ol {
	font-size: 1.125em;
	font-weight: 600;

	@media ( max-width: 1550px ) {
		font-size: 1em;
	}

	
	@media ( max-width: 450px ) {
		font-size: 0.875em;
	}

	ul, ol {
		font-size: 1em;
	}
}

h1, h2, h3, h4, h5, p {
	margin-top: 0;
}

p {
	font-size: 1.125em;
	font-weight: 600;

	@media ( max-width: 1550px ) {
		font-size: 1em;
	}

	@media ( max-width: 450px ) {
		font-size: 0.875em;
	}
}

.d-none { display: none; }


.heading {
	color: #216D4F;
    font-size: 1.5em;
	margin-bottom: 20px;
}

h3 {
	font-size: 1.25em;
}

.lines {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	gap: 16px;

	margin-bottom: 30px;

	@media ( max-width: 450px ) {
		font-size: 0.875em;
	}

	> div {
		font-weight: 600;
		border-radius: 16px;
		padding: 24px 24px;
		min-height: 64px;
		background: #F8F9F3;
		flex: 0 0 60%;

		&:nth-child(odd) {
			flex: auto;
		}
	}

	@media ( max-width: 920px ) {
		> div {
			flex: 1 1 100% !important;
			text-align: center;
		}
	}
}

.lines.date-lines {
	> div {
		flex: 0 0 75%;
		
		&:nth-child(odd) {
			flex: auto;
			text-align: center;
		}
	}
}

.container {
	max-width: 1310px;
	padding: 0 15px;
	margin: 0 auto;
}

.content-container {
	max-width: 990px;
	padding: 0 15px;
	margin: 0 auto;
}

.has-scrollbar {
	scrollbar-width: thin;
	scrollbar-color: $dark_blue $quartz;

	&::-webkit-scrollbar {
		width: 9px;
		height: 9px;
	}
	
	&::-webkit-scrollbar-track {
		background: $quartz;
	}
	
	&::-webkit-scrollbar-thumb {
		background-color: $dark_blue;
		outline: none;
	}
}

#inteliwise-agent {
	opacity: 0;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	overflow: hidden;
	display: none;

	.close-button {
		display: flex;
		position: absolute;
		right: 25px;
		top: 35px;
		z-index: 99999999;
		width: 30px;
		height: 30px;
	}

	&.visible {
		display: block;
		position: fixed;
		width: 100%;
		height: 100%;
		margin: 0;
		top: 0;
		left: 0;
		
		background-color: rgba(0,0,0, .7);
		
		z-index: 99999;
		opacity: 1;
	}

	.inteliwise-agent-container {
		max-width: 1230px;
		position: relative;
		padding: 0 10px;
	}

	&.visible > div {
		max-width: 1230px;
		margin: auto;
		padding: 0 10px;
	}
}


body.contrast {

	background: #000;
	color: $yellow;

	a, .heading {
		color: $yellow;
	}


	.has-scrollbar {
		scrollbar-color: $yellow $black;
		
		&::-webkit-scrollbar-track {
			background: $black;
		}
		
		&::-webkit-scrollbar-thumb {
			background-color: $yellow;
		}
	}

	.lines {
		> div {
			background: $black;
		}
	}

	svg {
		&.stroke {
			* { stroke: $yellow !important; }
		}

		&.with-lines {
			path:not(.lines ) { fill: $yellow !important; }
			path.lines { fill: #000 !important; }
		}

		&.fill {
			fill: yellow !important;
			* { fill: $yellow !important; }
		}
	
		&.fill-stroke {
			*.stroke { stroke: $yellow !important; }
			*.fill { fill: $yellow !important; }
		}
	}
}