@import "styles/colors.scss";
@import "styles/mixins.scss";

.main-slider {
	@include main-section;
	max-width: 1980px;
	max-height: 1200px;
	height: calc( var(--vh, 1vh ) * 100 );

	.navigation {
		width: 1280px;
		left: auto;
		right: calc(( 100% - 1280px ) / 2 );

		@media ( max-width: 1350px ) {
			width: auto;
			right: 15px;
		}
	}
}

.main-slider-slide-container {
	max-width: 1440px;
	padding: 0 80px 120px;
}

.main-slider-slide {
	@include img-bg;

	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	opacity: 0;
	z-index: 0;

	color: $white;
	transition: opacity .7s;

	&.active {
		opacity: 1;
		z-index: 10;
	}

	.container {
		@include flex( flex-start, flex-end, column );
		height: 100%;
		padding-bottom: 120px;

		@media ( max-width: 1400px ) {
			padding-bottom: 90px;
		}
	
		@media ( max-width: 680px ) {
			padding-bottom: 120px;
		}
	}
}

.main-slider-list {

	position: absolute;
	z-index: 10;
	bottom: 30px;

	display: flex;
	gap: 16px;

	width: 1280px;
	left: auto;
	right: calc(( 100% - 1280px ) / 2 );

	> div {
		cursor: pointer;
		flex: 1 1;
		text-align: center;
		color: $white;

		span {
			display: inline-block;
			margin-bottom: 16px;
			font-weight: 600;
		}

		> div {
			position: relative;
			height: 4px;
			background: rgba( 255, 255, 255, .4);

			div {
				position: absolute;
				height: 100%;
				width: 0;
				left: 0;
				top: 0;
				background: #fff;
			}
		}
	}

	> div.active {
		> div div {
			width: 100%;
		}
	}

	@media ( max-width: 1350px ) {
		width: auto;
		left: 15px;
		right: 15px;
	}

	@media ( max-width: 1024px ) {
		> div span {
			font-size: 12px;
		}
	}

	@media ( max-width: 680px ) {
		> div span {
			font-size: 10px;
		}
	}

	@media ( max-width: 450px ) {
		left: 8px;
		right: 8px;
		gap: 10px;

		> div span {
			font-size: 8px;
		}
	}
}

body.contrast {
	.main-slider-slide {
		color: $yellow;
	}

	.main-slider-list > div {
		color: $yellow;

		> div {
			background: rgba( $yellow, .4 );

			div {
				background: $yellow;
			}
		}
	}
}