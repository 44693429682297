@import "styles/mixins.scss";

.chart {
   padding: 40px 0;

	h3 {
		width: 100%;
		margin-top: 0;
		margin-bottom: 30px;
		font-weight: 600;
		font-size: 1.28em;
	}
}

.chart-legend {
   @include chart-legend;
   margin-bottom: 40px;
}