@import "styles/colors.scss";
@import "styles/mixins.scss";

.main-section {
	@include main-section;
	max-width: 1440px;

	> div {
		
		@include flex( center, flex-end, column );
		@include img-bg;
		
		background-color: $green;

		color: $white;
		text-align: center;
		min-height: 372px;
		padding: 80px;

		p {
			margin: 0;
			max-width: 60%;
			text-align: center;
		}

		@media ( max-width: 1400px ) {
			padding: 40px;
			justify-content: center;
		}

		@media ( max-width: 700px ) {
			padding: 30px 15px;

			p {
				max-width: 100%;
			}
		}
	}
}

.main-section.with-img > div {
	min-height: 486px;
	padding-bottom: 120px;

	@media ( max-width: 1400px ) {
		padding-bottom: 40px;
	}
}

body.contrast {
	.main-section > div {
		background-color: $black;
		color: $yellow;
	}
}
