@import "styles/colors.scss";
@import "styles/mixins.scss";

.carousel {
	padding: 40px 0;

	.tile {
		flex: 0 0 624px;

		@media ( max-width: 650px ) {
			flex: 0 0 calc( 100vw - 20px );
		}
	}

	.tile.with-img {
		flex: 0 0 490px;
		
		@media ( max-width: 530px ) {
			flex: 0 0 calc( 100vw - 20px );
		}
	}
}

.carousel-wrap {
	overflow: hidden;
	margin-left: calc(( 100% - 975px ) / 2 );

	@media ( max-width: 1014px) {
		margin-left: 15px;
	}
}

.carousel-top {
	@include flex( center );
	margin-bottom: 30px;

	> div {
		margin-left: auto;
	}

	h2 {
		font-size: 3em;
		color: $green;
		margin: 0;
		
		@media ( max-width: 1550px ) {
			font-size: 2em;
		}

		@media ( max-width: 450px ) {
			font-size: 1.4em;
		}
	}

	.btn {
		margin-bottom: 20px;
	}

	.arrows {
		justify-content: flex-end;
	}

	@media ( max-width: 650px ) {
		flex-wrap: wrap;

		h2 {
			width: 100%;
			margin-bottom: 10px;
		}
	}
}

.carousel-items {

	@include flex( center );
	gap: 25px;

	position: relative;
	left: 0;
	transition: left .3s;
}

.carousel.article {

	.content-container {
		max-width: 1310px;
	}

	.carousel-wrap {
		margin-left: calc(( 100% - 1280px ) / 2 );

		@media ( max-width: 1316px) {
			margin-left: 15px;
		}
	}
}

.carousel.green {
	background: $green;

	.arrows {
		svg { fill: $white; }

		button:hover {
			background: $white;
			border-color: $white;

			svg { fill: $green; }
		}
	}

	.carousel-top .btn {
		border-color: $white;
	}

	.carousel-top h2 {
		color: $white;
	}

	.tile {
		color: $white;
		border-color: $white;

		> svg { fill: $white; }

		&:hover {
			background: $white;
			color: $green;

			> svg { fill: $green; }
		}
	}
}

body.contrast {
	.carousel-top h2 {
		color: $yellow;
	}

	.carousel .arrows button {
		border-color: $yellow;

		svg { fill: $yellow; }

		&:hover {
			background: $yellow;

			svg { fill: $black; }
		}
	}


	.carousel.green {
		background: $black;
		.carousel-top h2 { color: $yellow; }

		.carousel-top .btn { border-color: $yellow; }

		.arrows {
			svg { fill: $yellow; }
	
			button:hover {
				background: $yellow;
				border-color: $yellow;
	
				svg { fill: $black; }
			}
		}

		.tile {
			color: $yellow;
			border-color: $yellow;
	
			> svg { fill: $yellow; }
	
			&:hover {
				background: $yellow;
				color: $black;
	
				> svg { fill: $black; }
			}
		}
	}
}